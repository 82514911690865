import * as React from "react";
import svgRank9 from "../svg/rank_9.svg";
import svgRank8 from "../svg/rank_8.svg";
import svgRank7 from "../svg/rank_7.svg";
import svgRank6 from "../svg/rank_6.svg";
import svgRank5 from "../svg/rank_5.svg";
import svgRank4 from "../svg/rank_4.svg";
import svgRank1 from "../svg/rank_1.svg";
import svgRankRyuuou from "../svg/rank_ryuuou.svg";
import svgRankMeijin from "../svg/rank_meijin.svg";
import svgRankOui from "../svg/rank_oui.svg";
import svgRankOuza from "../svg/rank_ouza.svg";
import svgRankKiou from "../svg/rank_kiou.svg";
import svgRankEiou from "../svg/rank_eiou.svg";
import svgRankOushyou from "../svg/rank_oushyou.svg";
import svgRankKisei from "../svg/rank_kisei.svg";
import styled from "styled-components";

const RankIconWrapper = styled.div`
  padding: 0.2rem;
`;

const RankIcon = ({ title }) => {
  let rank;

  if ("九段" === title) {
    rank = svgRank9;
  } else if ("八段" === title) {
    rank = svgRank8;
  } else if ("七段" === title) {
    rank = svgRank7;
  } else if ("六段" === title) {
    rank = svgRank6;
  } else if ("五段" === title) {
    rank = svgRank5;
  } else if ("四段" === title) {
    rank = svgRank4;
  } else if ("竜王" === title) {
    rank = svgRankRyuuou;
  } else if ("名人" === title) {
    rank = svgRankMeijin;
  } else if ("王位" === title) {
    rank = svgRankOui;
  } else if ("王座" === title) {
    rank = svgRankOuza;
  } else if ("棋王" === title) {
    rank = svgRankKiou;
  } else if ("叡王" === title) {
    rank = svgRankEiou;
  } else if ("王将" === title) {
    rank = svgRankOushyou;
  } else if ("棋聖" === title) {
    rank = svgRankKisei;
  } else {
    rank = svgRank1;
  }

  return (
    <RankIconWrapper>
      <img src={rank} alt={title} />
    </RankIconWrapper>
  );
};

export default RankIcon;
