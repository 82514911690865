import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import RankIcon from "./RankIcon";

const CardWrapper = styled.div`
  border-top: 1px solid #999999;
  &:hover {
    background: #20232a;
  }
  @media (max-width: 768px) {
    &:hover {
      background: #282c34;
    }
  }
`;

const StyledLink = styled(Link)`
  background: white;
  color: white;
  /* &:hover {
    color: #61dafb;
  } */
`;

const CardContent = styled.div`
  display: grid;
  grid-template-columns: 5fr 3fr;

  @media (max-width: 768px) {
    grid-template-columns: 2fr 1fr;
  }
`;

const PlayerInfo = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr;
  padding: 1rem 1rem;

  @media (max-width: 768px) {
    height: 100px;
    padding: 0.5rem;
  }
`;

const PlayerNo = styled.div`
  padding: 1rem;

  @media (max-width: 768px) {
    padding: 0.5rem;
    margin-top: 1.5rem;
  }
`;

const PlayerName2 = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column-reverse;
    padding: 0;
  }
`;

const PlayerName = styled.div`
  padding: 1rem;

  @media (max-width: 768px) {
    padding: 0;
    margin-bottom: 0.5rem;
  }
`;

const PlayerLevel = styled.div`
  display: flex;
  padding: 1rem 0rem 0rem 0rem;

  @media (max-width: 768px) {
    justify-content: center;
    margin-top: 0.5rem;
    margin-right: 0.5rem;
  }
`;

const PlayerCard = ({ data }) => {
  const rank = data.profile.rank.replace(/\(.+\)/g, "");
  const titles = rank.split(/[（）・]/);
  const titles2 = titles.filter((v) => v);

  return (
    <CardWrapper>
      <StyledLink to={`${data.type}/${data.no}`}>
        <CardContent>
          <PlayerInfo>
            <PlayerNo>{data.profile.no}</PlayerNo>
            <PlayerName2>
              <PlayerName>{data.profile.name_jp}</PlayerName>
              <PlayerName>{data.profile.name_en}</PlayerName>
            </PlayerName2>
          </PlayerInfo>
          <PlayerLevel>
            {titles2.map((title) => (
              <RankIcon title={title} />
            ))}
          </PlayerLevel>
        </CardContent>
      </StyledLink>
    </CardWrapper>
  );
};

export default PlayerCard;
