import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import Layout from "../components/Layout";
import ContentWrapper from "../components/ContentWrapper";
import PlayerCard from "../components/PlayerCard";
import SEO from "../components/SEO";

const CardsHeader = styled.div`
  display: grid;
  grid-template-columns: 2fr 6fr 5fr;
  padding: 1rem 1rem;
  color: #999999;
`;

const PlayersPage = ({ data }) => {
  const players = data.allPlayersActiveJson.nodes;

  return (
    <Layout title="Players">
      <SEO pagetitle="Players" pagedesc="棋士の一覧" />
      <ContentWrapper>
        <CardsHeader>
          <div>No</div>
          <div>Name</div>
          <div>Rank</div>
        </CardsHeader>
        {players.map((player) => (
          <PlayerCard data={player} />
        ))}
      </ContentWrapper>
    </Layout>
  );
};

export const pageQuery = graphql`
  query {
    allPlayersActiveJson {
      nodes {
        no
        name
        type
        profile {
          name_en
          name_jp
          no
          rank
        }
      }
    }
  }
`;

export default PlayersPage;
